import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterOutlinedIcon from "@mui/icons-material/FilterOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Box, Button, Link, Rating } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import Buttons from "../../Components/InputFields/Buttons/Buttons";
import DatePickerInput from "../../Components/InputFields/DatePicker/DatePicker";
import SelectDropdown from "../../Components/InputFields/SelectDropdown/SelectDropdown";
import TabButton from "../../Components/InputFields/TabButton/TabButton";
import {
  Facility,
  Meals,
  Policy,
  Reviews,
  Rules,
  TSpaces,
} from "../../Container/DacationsContainer";
import { COLORS } from "../../SupportingFiles/colors";
import PhotoTourPopup from "./PhotoTourPopup";

const DaCations = ({
  handleModifyDetailOpenModal,
  handleModifyDetailCloseModal,
  isModifyDetailModalOpen,
  Reviews,
  Spaces,
  explore_your_stay,
  facilities,
  policy,
  rules,
  faqs,
  meals,
  formData,
  setFormData,
  noofRooms,
  noofguests,
  handleChangeReserve,
  validate_form,
  openWhatsApp,
  shareLink,
}: {
  handleModifyDetailOpenModal: any;
  handleModifyDetailCloseModal: any;
  isModifyDetailModalOpen: any;
  explore_your_stay: any;
  facilities: Facility[];
  Spaces: TSpaces[];
  rules: Rules[];
  meals: Meals[];
  policy: Policy[];
  Reviews: Reviews[];
  faqs: Array<any>;
  formData: any;
  setFormData: any;
  noofRooms: any;
  noofguests: any;
  handleChangeReserve: Function;
  validate_form: Function;
  openWhatsApp: Function;
  shareLink: any;
}) => {
  const headerSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const spaceSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const Reviewslider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [guests, setGuests] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setGuests(event.target.value as string);
  };

  const [rooms, setRooms] = React.useState("");
  const handleRoom = (event: SelectChangeEvent) => {
    setRooms(event.target.value as string);
  };

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChangeFaq =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [showRulesContent, setShowRulesContent] = useState(false);
  const [showPolicyContent, setShowPolicyContent] = useState(false);
  const [showDescContent, setShowDescContent] = useState(false);

  const handleScrollTo = (sectionId: string) => {
    setActiveTab(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      const topOffset = section.offsetTop - 210; // Adjust the offset as needed
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  const [activeTab, setActiveTab] = useState("");
  useEffect(() => {
    const sectionIds = [
      "About",
      "Spaces",
      "Amenities",
      "Meals",
      "Experiences",
      "Rules",
      "Location",
      "Reviews",
      "Policy",
      "FAQ",
    ];

    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      for (const sectionId of sectionIds) {
        const section = document.getElementById(sectionId);
        if (section) {
          const { top, height } = section.getBoundingClientRect();
          if (top <= 240 && top + height > 240) {
            setActiveTab(sectionId);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap="40"
        alignItems="center"
        width="100%"
        padding="100px 12px 0px"
      >
        <Navbar />

        <Box maxWidth="1400px" width="100%">
          <Grid container spacing={3} marginTop="60px" marginBottom="40px">
            <Grid xs={12} className="headerSlider">
              <Carousel responsive={headerSlider}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid xs={7}>
                      <Box
                        className="imgEffect"
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "600px",
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={handleModifyDetailOpenModal}
                      >
                        <img
                          src="./images/pool.webp"
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                          alt="Kasauli Resort"
                        />
                        <Box
                          onClick={handleModifyDetailOpenModal}
                          sx={{
                            backgroundColor: "rgba(255,255,255,0.2)",
                            borderRadius: "8px",
                            padding: "8px 16px",
                            position: "absolute",
                            bottom: "24px",
                            right: "24px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            color: COLORS.white,
                          }}
                        >
                          <FilterOutlinedIcon />
                          <p style={{ color: COLORS.white }}>View Photos</p>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      xs={5}
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Box
                        className="imgEffect"
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "288px",
                          cursor: "pointer",
                        }}
                        onClick={handleModifyDetailOpenModal}
                      >
                        <img
                          src="./images/living-room.webp"
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                          alt="Hotel in Kasauli"
                        />
                      </Box>
                      <Box
                        className="imgEffect"
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "288px",
                          cursor: "pointer",
                        }}
                        onClick={() => {}}
                      >
                        <img
                          src="./images/bedroom.webp"
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                          alt="Villa in Kasauli"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid
                      xs={4}
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "288px",
                          cursor: "pointer",
                        }}
                        onClick={handleModifyDetailOpenModal}
                      >
                        <img
                          src="./images/living-room-3.webp"
                          width="100%"
                          height="100%"
                          className="imgZoom"
                          style={{ objectFit: "cover" }}
                          alt="luxury rooms"
                        />
                      </Box>
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "288px",
                          cursor: "pointer",
                        }}
                        onClick={() => {}}
                      >
                        <img
                          src="./images/bedroom-7.webp"
                          width="100%"
                          height="100%"
                          className="imgZoom"
                          style={{ objectFit: "cover" }}
                          alt="Luxury Bedroom"
                        />
                      </Box>
                    </Grid>
                    <Grid
                      xs={4}
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "288px",
                          cursor: "pointer",
                        }}
                        onClick={handleModifyDetailOpenModal}
                      >
                        <img
                          src="./images/terrace.webp"
                          width="100%"
                          height="100%"
                          className="imgZoom"
                          style={{ objectFit: "cover" }}
                          alt="terrace"
                        />
                      </Box>
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "288px",
                          cursor: "pointer",
                        }}
                        onClick={() => {}}
                      >
                        <img
                          src="./images/additional-photo.webp"
                          width="100%"
                          height="100%"
                          className="imgZoom"
                          style={{ objectFit: "cover" }}
                          alt="Hills view"
                        />
                      </Box>
                    </Grid>
                    <Grid
                      xs={4}
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "288px",
                          cursor: "pointer",
                        }}
                        onClick={handleModifyDetailOpenModal}
                      >
                        <img
                          src="./images/pool-2.webp"
                          width="100%"
                          height="100%"
                          className="imgZoom"
                          style={{ objectFit: "cover" }}
                          alt="Heated pool"
                        />
                      </Box>
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "288px",
                          cursor: "pointer",
                        }}
                        onClick={() => {}}
                      >
                        <img
                          src="./images/full-bathroom-1.webp"
                          width="100%"
                          height="100%"
                          className="imgZoom"
                          style={{ objectFit: "cover" }}
                          alt="Air conditioning bathroom"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid xs={4}>
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "600px",
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={() => {}}
                      >
                        <img
                          src="./images/dining-area-2.webp"
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                          className="imgZoom"
                          alt="Dining table"
                        />
                        <Box
                          sx={{
                            backgroundColor: "rgba(255,255,255,0.2)",
                            borderRadius: "8px",
                            padding: "8px 16px",
                            position: "absolute",
                            bottom: "24px",
                            right: "24px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            color: COLORS.white,
                          }}
                        >
                          <FilterOutlinedIcon />
                          <p style={{ color: COLORS.white }}>View Photos</p>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      xs={4}
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "600px",
                          cursor: "pointer",
                          zIndex: 99,
                        }}
                        onClick={handleModifyDetailOpenModal}
                      >
                        <img
                          onClick={handleModifyDetailOpenModal}
                          src="./images/entry.webp"
                          width="100%"
                          height="100%"
                          className="imgZoom"
                          style={{ objectFit: "cover" }}
                          alt="Grand Entry"
                        />
                      </Box>
                    </Grid>
                    <Grid
                      xs={4}
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Box
                        sx={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          height: "600px",
                          cursor: "pointer",
                        }}
                        onClick={() => {}}
                      >
                        <img
                          src="./images/full-bathroom-3.webp"
                          width="100%"
                          height="100%"
                          className="imgZoom"
                          style={{ objectFit: "cover" }}
                          alt="Hot Water"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Carousel>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={7} lg={6}>
              <h2>
                Salud by DaCations
                <Link
                  onClick={shareLink}
                  sx={{ color: COLORS.black, padding: "0px 8px" }}
                >
                  <ShareOutlinedIcon />
                </Link>
              </h2>
              <p style={{ opacity: "0.5" }}>
                Dhako, Near Kasauli, Himachal Pradesh, 173229
              </p>
              <Box display="flex" gap="24px" marginTop="24px">
                <Box
                  sx={{
                    backgroundColor: "rgba(248, 198, 75, 0.10)",
                    borderRadius: "100px",
                    padding: "8px 24px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <p style={{ paddingTop: "4px" }}>4.8</p>
                  <StarRoundedIcon sx={{ height: "24px" }} />
                </Box>
                <Box
                  sx={{
                    border: "1px dashed #000",
                    borderRadius: "100px",
                    padding: "8px 24px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <ThumbUpOffAltIcon />
                  <p>Guest Favourite</p>
                </Box>
              </Box>
              <ul
                style={{
                  backgroundColor: "rgba(248, 198, 75, 0.10)",
                  borderRadius: "8px",
                  padding: "12px 24px",
                  display: "flex",
                  gap: "40px",
                  alignItems: "center",
                  marginTop: "24px",
                }}
              >
                <li>Entire place</li>
                <li>Max. 11 Guests</li>
                <li>4 Rooms</li>
                <li>4 Baths</li>
              </ul>
              <h3 style={{ marginTop: "24px" }}>
                <span style={{ fontWeight: "300" }}>
                  Here why da cations should be your
                </span>{" "}
                first choice!
              </h3>

              {/* Facilities */}
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-start"
                gap="24px"
                marginTop="24px"
              >
                {facilities.map((facility, index) => (
                  <Box
                    key={index}
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        borderRadius: "100px",
                        backgroundColor: "#FFF4F3",
                        marginBottom: "12px",
                        padding: "8px",
                        height: "80px",
                        width: "80px",
                      }}
                    >
                      <img
                        src={facility.iconUrl}
                        alt={facility.name}
                        width="100%"
                      />
                    </Box>
                    <p style={{ maxWidth: "90px", textAlign: "center" }}>
                      {facility.name}
                    </p>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid xs={5} lg={6}>
              <Box
                sx={{
                  background:
                    "linear-gradient(122deg, rgba(139, 205, 172, 0.20) 0%, rgba(248, 198, 75, 0.20) 100%)",
                  padding: "24px",
                  borderRadius: "20px",
                }}
              >
                <h2>
                  <span style={{ fontSize: "14px" }}>(Starting)</span>
                  ₹44,500{" "}
                </h2>
                <p style={{ opacity: "0.5", marginBottom: "24px" }}>
                  (4 Rooms / 8 Adults / Night + Taxes)
                </p>
                <Grid container spacing={3}>
                  <Grid xs={12} lg={6}>
                    <p>Check-In</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePickerInput
                        attrName={"checkIn"}
                        class_name="datepicker"
                        name="checkIn"
                        value={formData.checkIn.value}
                        value_update={(atrrName: string, e: any) =>
                          handleChangeReserve(e, "checkIn")
                        }
                        min_date={15 * 12}
                        max_date={3}
                        date_validation_type="MONTHS"
                        sx={{ width: "100%", backgroundColor: COLORS.white }}
                        warn_status={formData.checkIn.warning}
                        error_message="select checkIn"
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <p>Check-Out</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePickerInput
                        attrName={"checkOut"}
                        class_name="datepicker"
                        name="checkOut"
                        value_update={(attrName: string, e: any) =>
                          handleChangeReserve(e, "checkOut")
                        }
                        sx={{ width: "100%", backgroundColor: COLORS.white }}
                        min_date={15 * 12}
                        max_date={3}
                        date_validation_type="MONTHS"
                        value={formData.checkOut.value}
                        warn_status={formData.checkOut.warning}
                        error_message={"select checkOut "}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <SelectDropdown
                      name="noOfGuests"
                      value={formData.noOfGuests.value}
                      handleChange={(e: any) =>
                        handleChangeReserve(e, "noOfGuests")
                      }
                      label="Guests"
                      menuItems={noofguests}
                      warn_status={formData.noOfGuests.warning}
                      error_message={"select guests"}
                    />
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <SelectDropdown
                      name="noOfRooms"
                      value={formData.noOfRooms.value}
                      handleChange={(e: any) =>
                        handleChangeReserve(e, "noOfRooms")
                      }
                      label="No. of Rooms"
                      menuItems={noofRooms}
                      warn_status={formData.noOfRooms.warning}
                      error_message={"select rooms"}
                    />
                  </Grid>
                  <Grid xs={8} xsOffset={2} textAlign="center">
                    <Buttons
                      text="Reserve"
                      fullWidth
                      onClick={() => {
                        validate_form();
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          {/* Section Tabs */}
          <Grid container spacing={3} className="mb-20 pt-10 stickyGrid">
            <Grid xs={12} display="flex" gap="12px" flexWrap="wrap">
              <TabButton
                text="About"
                active={activeTab === "About"}
                onClick={() => handleScrollTo("About")}
              />
              <TabButton
                text="Spaces"
                active={activeTab === "Spaces"}
                onClick={() => handleScrollTo("Spaces")}
              />
              <TabButton
                text="Amenities"
                active={activeTab === "Amenities"}
                onClick={() => handleScrollTo("Amenities")}
              />
              <TabButton
                text="Meals"
                active={activeTab === "Meals"}
                onClick={() => handleScrollTo("Meals")}
              />
              <TabButton
                text="Experiences"
                active={activeTab === "Experiences"}
                onClick={() => handleScrollTo("Experiences")}
              />
              <TabButton
                text="Rules"
                active={activeTab === "Rules"}
                onClick={() => handleScrollTo("Rules")}
              />
              <TabButton
                text="Location"
                active={activeTab === "Location"}
                onClick={() => handleScrollTo("Location")}
              />
              <TabButton
                text="Reviews"
                active={activeTab === "Reviews"}
                onClick={() => handleScrollTo("Reviews")}
              />
              <TabButton
                text="Policy"
                active={activeTab === "Policy"}
                onClick={() => handleScrollTo("Policy")}
              />
              <TabButton
                text="FAQ"
                active={activeTab === "FAQ"}
                onClick={() => handleScrollTo("FAQ")}
              />
            </Grid>
          </Grid>

          {/* About */}
          <Grid container spacing={3} className="mb-20" id="About">
            <Grid xs={12}>
              <h5>Explore Your Stay</h5>

              <Box
                sx={{
                  maxHeight: showDescContent ? "100%" : "109px",
                  overflow: "hidden",
                  transition: "all ease-in-out",
                  marginTop: "12px",
                }}
              >
                {explore_your_stay}
              </Box>
              <Link
                style={{
                  color: COLORS.primary,
                  fontSize: "16px",
                  fontWeight: "bold",
                  textDecorationColor: COLORS.primary,
                  display: "inline-block",
                  cursor: "pointer",
                  marginTop: "16px",
                }}
                onClick={() => {
                  setShowDescContent(!showDescContent);
                }}
              >
                {showDescContent ? "Show Less" : "Read More"}{" "}
              </Link>
            </Grid>
          </Grid>

          {/* Spaces */}
          <Grid container spacing={3} className="mb-20" id="Spaces">
            <Grid xs={12}>
              <h3>Spaces</h3>
            </Grid>
            <Grid xs={12}>
              <Carousel
                responsive={spaceSlider}
                renderArrowsWhenDisabled={true}
              >
                {Spaces.map((data, index) => (
                  <Box padding="0px 12px 24px">
                    <Box
                      sx={{
                        borderRadius: "20px",
                        overflow: "hidden",
                      }}
                    >
                      <img src={data.image} width="100%" alt="Bedroom" />
                    </Box>
                    <Box
                      sx={{
                        boxShadow: "0px 12px 12px rgba(0,0,0,0.1)",
                        borderRadius: "20px",
                        padding: "20px",
                      }}
                    >
                      <h5>{data.title}</h5>
                      <ul
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {data.data.map((sub_data, index) => (
                          <li
                            style={{
                              listStyle: "none",
                              display: "flex",
                              gap: "8px",
                            }}
                          >
                            <img
                              src="./images/hexagon-tick-icon.png"
                              width="15px"
                              height="17px"
                              alt="icon"
                            />
                            <p>{sub_data}</p>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                ))}
              </Carousel>
            </Grid>
          </Grid>

          {/* Amenities */}
          <Grid container spacing={3} className="mb-20" id="Amenities">
            <Grid xs={12}>
              <h3>Amenities</h3>
            </Grid>
            <Grid xs={2} md={3} lg textAlign="center">
              <Box>
                <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                  <img
                    src="./images/wardrobe.jpg"
                    width="100%"
                    alt="wardrobe"
                  />
                </Box>
                <h5>Wardrobe</h5>
              </Box>
            </Grid>
            <Grid xs={2} md={3} lg textAlign="center">
              <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                <img src="./images/speaker.jpg" width="100%" alt="speaker" />
              </Box>
              <h5>Music System/ Speaker</h5>
            </Grid>
            <Grid xs={2} md={3} lg textAlign="center">
              <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                <img
                  src="./images/toiletries.jpg"
                  width="100%"
                  alt="toiletries"
                />
              </Box>
              <h5>Toiletries</h5>
            </Grid>
            <Grid xs={2} md={3} lg textAlign="center">
              <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                <img src="./images/mattress.jpg" width="100%" alt="mattress" />
              </Box>
              <h5>Extra Mattress</h5>
            </Grid>
            <Grid xs={2} md={3} lg textAlign="center">
              <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                <img src="./images/towles.jpg" width="100%" alt="towles" />
              </Box>
              <h5>Towels</h5>
            </Grid>
          </Grid>

          {/* Meals */}
          <Grid container spacing={3} className="mb-20" id="Meals">
            <Grid xs={12}>
              <h3>Meals</h3>
            </Grid>
            <Grid xs={12} md={6}>
              <Box borderRadius="20px" overflow="hidden">
                <img
                  src="./images/meal.jpg"
                  width="100%"
                  alt="Meal, Barbecue Experiences"
                />
              </Box>
            </Grid>
            <Grid xs={12} md={6} alignSelf="center">
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginLeft: "12px",
                }}
              >
                {meals.map((data, index) => (
                  <li
                    style={{
                      color: COLORS.darkgrey,
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    <strong>{data.title}</strong> - {data.desc}
                  </li>
                ))}
              </ul>
              <Box display="flex" gap="24px" marginTop="20px">
                <Button
                  sx={{
                    border: "1px solid",
                    borderColor: COLORS.darkgrey,
                    color: COLORS.darkgrey,
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    gap: "8px",
                    flex: "1",
                  }}
                >
                  <img
                    src="./images/veg-icon.svg"
                    height="14px"
                    alt="veg food"
                  />
                  Veg
                </Button>
                <Button
                  sx={{
                    border: "1px solid",
                    borderColor: COLORS.darkgrey,
                    color: COLORS.darkgrey,
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    gap: "8px",
                    flex: "1",
                  }}
                >
                  <img
                    src="./images/mix-menu-icon.svg"
                    height="14px"
                    alt="non-veg food"
                  />
                  Non-Veg
                </Button>
                <Button
                  sx={{
                    border: "1px solid",
                    borderColor: COLORS.darkgrey,
                    color: COLORS.darkgrey,
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    gap: "8px",
                    flex: "1",
                  }}
                >
                  <a
                    href="./pdfs/Salud_menuV1.pdf"
                    target="_blank"
                    style={{
                      color: "inherit", // inherits color from parent (Button)
                      textDecoration: "none", // removes underline
                    }}
                  >
                    View Menu
                  </a>
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* Experiences */}
          <Grid container spacing={3} className="mb-20" id="Experiences">
            <Grid xs={12}>
              <h3>Experiences</h3>
            </Grid>
            <Grid xs={6} md={4} textAlign="center">
              <Box border="1px solid #8BCDAC" borderRadius="24px">
                <Box borderRadius="20px" overflow="hidden" margin="8px">
                  <img
                    src="./images/experience-1.jpg"
                    width="100%"
                    alt="adventure"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={4} textAlign="center">
              <Box border="1px solid #8BCDAC" borderRadius="24px">
                <Box borderRadius="20px" overflow="hidden" margin="8px">
                  <img
                    src="./images/experience-2.jpg"
                    width="100%"
                    alt="relaxation"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={4} textAlign="center">
              <Box border="1px solid #8BCDAC" borderRadius="24px">
                <Box borderRadius="20px" overflow="hidden" margin="8px">
                  <img
                    src="./images/experience-3.jpg"
                    width="100%"
                    alt="karaoke night"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <p>
                Whether you're seeking relaxation, adventure, or simply a break
                from the ordinary, dacations's immersive experiences tailored
                meet your preferences. Have a private celebration or just enjoy
                a bonfire and karaoke night under the stars.
              </p>
            </Grid>
          </Grid>

          {/* House Rules */}
          <Box
            boxShadow="0px 12px 12px rgba(0,0,0,0.1)"
            borderRadius="20px"
            padding="24px"
            marginBottom="80px"
            marginRight="24px"
            id="Rules"
          >
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h3>Home Rules and Truths</h3>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    marginTop: "24px",
                    marginBottom: "24px",
                    paddingLeft: "12px",
                    maxHeight: showRulesContent ? "100%" : "280px",
                    overflow: "hidden",
                    transition: "all ease-in-out",
                  }}
                >
                  {rules.map((Rules, index) => (
                    <li
                      style={{
                        color: COLORS.darkgrey,
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      {Rules.desc}
                    </li>
                  ))}
                </ul>
                <Link
                  style={{
                    color: COLORS.primary,
                    fontSize: "16px",
                    fontWeight: "bold",
                    textDecorationColor: COLORS.primary,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowRulesContent(!showRulesContent);
                  }}
                >
                  {showRulesContent ? "Show Less" : "Read More"}{" "}
                </Link>
              </Grid>
            </Grid>
          </Box>

          {/* Location */}
          <Grid container spacing={3} className="mb-20" id="Location">
            <Grid xs={12}>
              <h3>Location</h3>
            </Grid>
            <Grid xs={12}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13698.374954091367!2d77.0783136!3d30.8700474!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f85353108b9b5%3A0x2c88055be14ee7f4!2sSalud!5e0!3m2!1sen!2sin!4v1708431190473!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowFullScreen={true}
                loading="lazy"
                style={{
                  border: "none",
                  borderRadius: "20px",
                  marginBottom: "12px",
                }}
              ></iframe>
              {/* <p>
                An escapist hill station surrounded by green valleys and
                waterfalls, Lonavala is one of the most sought-after tourist
                getaways near Mumbai and Pune. It is a great way to skip town
                and live in the lap
              </p>
              <Link
                style={{
                  color: COLORS.primary,
                  fontSize: "16px",
                  fontWeight: "bold",
                  textDecorationColor: COLORS.primary,
                }}
              >
                Read More
              </Link> */}
            </Grid>
          </Grid>

          {/* Reviews */}
          <Box
            boxShadow="0px 12px 12px rgba(0,0,0,0.1)"
            borderRadius="20px"
            padding="24px"
            marginBottom="80px"
            marginRight="24px"
            id="Reviews"
          >
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h3>Reviews</h3>
                <p>"See what our guests have to say about us!!"</p>
              </Grid>
              <Grid xs={12}>
                <Carousel
                  responsive={Reviewslider}
                  renderArrowsWhenDisabled={true}
                >
                  {Reviews.map((Reviews, index) => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                      marginBottom="24px"
                      padding="0px 6px"
                    >
                      <Box
                        sx={{
                          backgroundColor: COLORS.darkgrey,
                          color: COLORS.white,
                          display: "flex",
                          gap: "32px",
                          alignItems: "center",
                          padding: "20px",
                          borderRadius: "12px",
                          marginBottom: "12px",
                        }}
                      >
                        <Box
                          height="78px"
                          width="78px"
                          borderRadius="100px"
                          overflow="hidden"
                        >
                          <img src={Reviews.imgUrl} width="100%" alt="img" />
                        </Box>
                        <Box>
                          <h5>{Reviews.name}</h5>
                          <p
                            style={{
                              fontSize: "16px",
                              color: COLORS.white,
                              fontWeight: "300",
                              marginTop: "12px",
                            }}
                          >
                            - {Reviews.designation}
                          </p>
                        </Box>
                      </Box>
                      <h6>"{Reviews.feedbackTitle}"</h6>
                      <Box sx={{ color: COLORS.primary }}>
                        <Rating
                          name="size-large"
                          readOnly
                          defaultValue={Reviews.rating}
                          size="large"
                        />
                      </Box>
                      <p>{Reviews.feedback}</p>
                    </Box>
                  ))}
                </Carousel>
              </Grid>
            </Grid>
          </Box>

          {/* Policy */}
          <Box
            boxShadow="0px 12px 12px rgba(0,0,0,0.1)"
            borderRadius="20px"
            padding="24px"
            marginBottom="80px"
            marginRight="24px"
            id="Policy"
          >
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h3>Policy</h3>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    marginTop: "24px",
                    marginBottom: "24px",
                    paddingLeft: "12px",
                    maxHeight: showPolicyContent ? "100%" : "280px",
                    overflow: "hidden",
                    transition: "all ease-in-out",
                  }}
                >
                  {policy.map((Policy, index) => (
                    <li
                      style={{
                        color: COLORS.darkgrey,
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      {Policy.desc}
                    </li>
                  ))}
                </ul>
                <Link
                  style={{
                    color: COLORS.primary,
                    fontSize: "16px",
                    fontWeight: "bold",
                    textDecorationColor: COLORS.primary,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowPolicyContent(!showPolicyContent);
                  }}
                >
                  {showPolicyContent ? "Show Less" : "Read More"}{" "}
                </Link>
              </Grid>
            </Grid>
          </Box>

          {/* FAQ */}
          <Box
            id="FAQ"
            sx={{
              borderRadius: "20px",
              padding: "24px",
              backgroundColor: "#F6F7F8",
              marginRight: "24px",
            }}
          >
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h3>
                  <span style={{ fontWeight: "300" }}>Frequently Asked</span>{" "}
                  Questions
                </h3>
              </Grid>
              <Grid xs={12}>
                {faqs.map((faq) => (
                  <Accordion
                    key={faq.id}
                    expanded={expanded === faq.id}
                    onChange={handleChangeFaq(faq.id)}
                    sx={{
                      borderRadius: "12px",
                      border: "1px solid #ccc",
                      boxShadow: "0px 0px 0px rgba(0,0,0,0)",
                      marginBottom: "24px",
                      "&.Mui-expanded": {
                        boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                      },
                      "&:first-of-type": {
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      },
                      "&:last-of-type": {
                        borderBottomLeftRadius: "12px",
                        borderBottomRightRadius: "12px",
                      },
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${faq.id}-content`}
                      id={`${faq.id}-header`}
                    >
                      <h5>{faq.question}</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{faq.answer}</p>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Photo Tour Popup */}
        <PhotoTourPopup
          open={isModifyDetailModalOpen}
          onclose={handleModifyDetailCloseModal}
        />
      </Box>
      <Footer />
    </>
  );
};

export default DaCations;
